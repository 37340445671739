<template>
  <div class="video-swiper">
    <img :src="theme==='blue' ? blueLeft : whiteLeft" @click="slidePrev" class="left-button button">
    <swiper class="swiper" ref="mySwiper" :options="swiperOptions">
      <swiper-slide v-for="(videoUrl, idx) in videos" :key="idx">
        <video webkit-playsinline="true" playsinline="true" :ref="'video'+idx" autoplay muted loop :src="videoUrl"></video>
      </swiper-slide>
    </swiper>
    <img :src="theme==='blue' ? blueRight : whiteRight"  @click="slideNext" class="right-button button">
  </div>
</template>

<script>
  export default {
    props: ['videos', 'theme'],
    data(){
      return {
        swiperOptions: {
          pagination: {
            el: '.swiper-pagination'
          },
          loop: true
        },
        whiteLeft: require('../../assets/arrow-white-left.png'),
        whiteRight: require('../../assets/arrow-white-right.png'),
        blueLeft: require('../../assets/arrow-left.png'),
        blueRight: require('../../assets/arrow-right.png'),
      }
    },
    computed: {
      swiper() {
        return this.$refs.mySwiper.swiper
      }
    },
    mounted() {

    },
    methods: {
      slidePrev(){
        this.swiper.slidePrev()
        const index = this.swiper.activeIndex
        this.swiper.el.querySelectorAll('video')[index].currentTime = 0
      },
      slideNext(){
        this.swiper.slideNext()
        const index = this.swiper.activeIndex
        this.swiper.el.querySelectorAll('video')[index].currentTime = 0
      }
    }
  }
</script>

<style lang="scss" scoped>
  .video-swiper{
    width: 520px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 0px 40px;
    box-sizing: border-box;
    .button{
      width: 26px;
      height: 42px;
      cursor: pointer;
    }
    .swiper{
      width: 220px;
      height: 462px;
      background: #000;
      filter: drop-shadow(0px 40px 80px rgba(0, 0, 0, 0.6));
      border-radius: 20px;
      video{
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
</style>