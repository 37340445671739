<template>
    <div id="download">
        <div class="mask"></div>
        <div class="content">
            <div class="left">
                <div class="slogen">
                    Discover and share with your neighbour
                </div>
                <div class="description">
                    Create, watch, and share short, entertaining videos with friends and neighbours - whether it's an unboxing or dining experience, we bring you closer to the people and things you love.
                </div>
                <Form class="form" :showTips="true"></Form>
                <div class="store">
                    <div class="store-privacy"></div>
                    <a href="https://apps.apple.com/sg/app/webuy-sg/id1522669075">
                        <img src="../../assets/app_store.png" alt="">
                    </a>
                    <a href="https://play.google.com/store/apps/details?id=com.webuy.sg&hl=en_US&gl=US">
                        <img src="../../assets/google_play.png" alt="">
                    </a>
                    <a href="javascript: void;">
                        <img @click="show" src="../../assets/wechat.png" alt="">
                    </a>
                </div>
            </div>
            <div class="right">
                <video webkit-playsinline="true" playsinline="true" @click="setVideo" ref="video" autoplay muted loop :src="videoUrl"></video>
            </div>
        </div>

        <div class="modal" v-show="showModal">
            <div class="left">
                <div class="title">Scan to Open</div>
                <div class="sub-title">Start shopping on WEBUY in We Chat</div>
                <div class="button" @click="hideModal">Got it!</div>
            </div>
            <img src="../../assets/wechat-code.png" alt="">
        </div>
    </div>
</template>

<script>
import Form from "./Form.vue";
export default {
    components: {
        Form,
    },
    data() {
        return {
            showModal: false,
            videoUrl: "https://prod-webuysg.oss.webuy.ren/video/top1.mp4",
        };
    },
    methods: {
        setVideo() {
            const $video = this.$refs.video;
            console.dir($video);
            $video.muted = !$video.muted;
        },
        hideModal() {
            this.showModal = false;
        },
        show() {
            this.showModal = true;
        },
    },
};
</script>

<style lang="scss" scoped>
#download {
    &::before {
        content: "";
        display: block;
        height: 64px;
        margin-top: -64px;
        visibility: hidden;
    }
    position: relative;
    width: 100%;
    height: 700px;
    background-image: url("../../assets/bg.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    color: #fff;
    margin-top: 64px;
    .mask {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.4);
    }
    .content {
        position: relative;
        width: 1040px;
        height: 100%;
        margin: 0 auto;
        display: flex;
        align-items: center;
        z-index: 1;
        .left {
            width: 440px;
            margin: 0 40px;
            .slogen {
                font-weight: bold;
                font-size: 54px;
                line-height: 60px;
            }
            .description {
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;
                margin-top: 10px;
            }
            .form {
                margin: 30px 0;
            }
            .store {
                a {
                    margin-right: 20px;
                    img {
                        width: 118px;
                        height: 38px;
                    }
                }
            }
        }
        .right {
            margin-left: 130px;
            width: 260px;
            height: 546px;
            border-radius: 20px;
            background: #000;
            overflow: hidden;
            video {
                width: 100%;
                height: 100%;
                object-fit: cover;
                cursor: pointer;
                border-radius: 20px;
            }
        }
    }
    .modal {
        position: fixed;
        width: 1040px;
        height: 500px;
        left: 50%;
        margin-left: -520px;
        top: 140px;
        z-index: 100;
        display: flex;
        padding-left: 40px;
        padding-right: 88px;
        background-color: #fff;
        justify-content: space-between;
        align-items: center;
        box-sizing: border-box;
        .left {
            .title {
                font-weight: bold;
                font-size: 32px;
                line-height: 52px;
                color: #02283f;
            }
            .sub-title {
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;
                color: #5c5c5c;
            }
            .button {
                width: 200px;
                height: 40px;
                background: #0060ff;
                border-radius: 14px;
                color: #fff;
                font-weight: 500;
                font-size: 14px;
                line-height: 40px;
                text-align: center;
                margin-top: 40px;
                cursor: pointer;
            }
        }
        img {
            width: 344px;
            height: 344px;
        }
    }
}
</style>
