<template>
    <div id="about">
        <div class="feature-container">
            <div class="title">ABOUT</div>
            <div class="sub-title">WEBUY is a social e-commerce platform that brings you the latest trend of group buying</div>
            <div class="desc">By purchasing with WEBUY, we can buy in bulk and get you lower prices. Download the app and join a group near you to start enjoying savings!</div>
            <div class="features">
                <div class="feature" v-for="feat in features" :key="feat.name">
                    <img class="icon" :src="feat.icon">
                    <div class="name">{{feat.name}}</div>
                    <div class="content">{{feat.content}}</div>
                </div>
            </div>
        </div>
        <div class="scene-container">
            <div class="scences">
                <div v-for="(scence, index) in scences" :class="[index%2 ? 'odd' : 'even','scence']" :key="scence.title">
                    <img :src="scence.pic" alt="" class="pic">
                    <div class="title">{{scence.title}}</div>
                    <div class="desc">{{scence.desc}}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            features: [
                {
                    icon: require("../../assets/discount.png"),
                    name: "Great Deals",
                    content: "Groupbuying and direct from supplier means 20-50% savings",
                },
                {
                    icon: require("../../assets/food-delivery.png"),
                    name: "Order Food You Love",
                    content: "No markup",
                },
                {
                    icon: require("../../assets/shopping-basket.png"),
                    name: "Video Shopping",
                    content:
                        "Add to cart directly from short videos, earn royalty by posting video reviews",
                },
                {
                    icon: require("../../assets/location.png"),
                    name: "Convenient",
                    content: "Free pick up 200m from your location island-wide with no min. spend",
                },
            ],
            scences: [
                {
                    pic: require("../../assets/scence1.jpg"),
                    title: "Fresh",
                    desc: "Discover a wide range of products – from fresh produce, frozen products, ready-to-eat meals and vouchers!",
                },
                {
                    pic: require("../../assets/scence2.jpg"),
                    title: "Lifestyle",
                    desc: "Fulfil your daily needs with WEBUY: dining vouchers, household items, beauty products and repair services",
                },
                {
                    pic: require("../../assets/scence3.jpg"),
                    title: "Food Delivery",
                    desc: "Enjoy food from popular restaurants from the comfort of your home – at attractive prices",
                },
                {
                    pic: require("../../assets/scence4.jpg"),
                    title: "Local Service",
                    desc: "Explore the latest, cool restaurants in the city and dine in with your friends and family",
                },
            ],
        };
    },
};
</script>

<style lang="scss" scoped>
#about {
    &::before {
        content: "";
        display: block;
        height: 64px;
        margin-top: -64px;
        visibility: hidden;
    }
    .feature-container {
        width: 1040px;
        margin: 0 auto;
        text-align: center;
        margin-bottom: 60px;
        .title {
            margin-top: 30px;
            font-style: normal;
            font-weight: 600;
            font-size: 20px;
            line-height: 30px;
            letter-spacing: 4px;
            color: #02283f;
        }
        .sub-title {
            font-weight: bold;
            font-size: 32px;
            line-height: 48px;
            color: #0060ff;
            margin-top: 28px;
        }
        .desc {
            margin-top: 24px;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: #5c5c5c;
        }
        .features {
            display: flex;
            margin-top: 30px;
            .feature {
                flex: 1;
                height: 190xp;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: center;
                .icon {
                    width: 40px;
                    height: 40px;
                }
                .name {
                    font-weight: 600;
                    font-size: 24px;
                    line-height: 32px;
                    color: #02283f;
                    padding: 0 34px;
                }
                .content {
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 21px;
                    color: #5c5c5c;
                    padding: 0 40px;
                }
            }
        }
    }
    .scene-container {
        width: 100%;
        height: 640px;
        background: #0060ff;
        display: flex;
        align-items: center;
        .scences {
            width: 1040px;
            display: flex;
            margin: 0 auto;
            justify-content: space-between;
            .scence {
                text-align: center;
                color: #ffffff;
                font-size: 0;
                &.even {
                    margin-top: -30px;
                }
                &.odd {
                    margin-top: 30px;
                }
                .pic {
                    width: 250px;
                    height: 250px;
                }
                .title {
                    font-weight: bold;
                    font-size: 32px;
                    line-height: 48px;
                    margin-top: 20px;
                }
                .desc {
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 21px;
                    margin: 0 auto;
                    margin-top: 10px;
                    max-width: 170px;
                }
            }
        }
    }
}
</style>