<template>
    <div class="header">
        <div class="content">
            <span class="logo" @click="toDownload">
                <img src="../../assets/Bear.png" alt="logo" class="icon">
                <img src="../../assets/name.svg" alt="name" class="name">
            </span>
            <nav class="navs">
                <template v-for="link in links">
                    <span @click="open(link)" :class="['nav', {active: active === link.name}]" :key="link.name" :href="link.path">{{link.name}}</span>
                </template>
            </nav>
        </div>
    </div>
</template>

<script>
import { links } from "@/utils.js";
export default {
    data() {
        return {
            links,
            active: ""
        };
    },
    created() {
        let route = this.links.find(link => link.path === this.$route.fullPath);
        if (route) {
            this.active = route.name;
        }
    },
    methods: {
        toDownload() {
            this.active = "Download";
            this.$router.push({ path: "/#download" });
            if (this.$route.path === "/") {
                document.querySelector("#download").scrollIntoView();
            }
        },
        toBuahKita() {
            this.$router.push({ path: "/buahKita" });
        },
        open(link) {
            this.active = link.name;
            this.navOpen = false;
            if (link.path.startsWith("http")) {
                window.open(link.path);
                return;
            } 
            if (link.path === "/buahKita") {
                this.$router.push({ path: "/buahKita" });
                return;
            }
            if (this.$route.path === "/") {
                let split = link.path.split("#");
                if (split.length && split[0] === "/" && split[1]) {
                    document.querySelector("#" + split[1]).scrollIntoView();
                }
                return
            } else {
                this.$router.push({ path: link.path });
            }
            
        },
    }
};
</script>

<style lang="scss" scoped>
.header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background: #fff;
    z-index: 100;
}
.content {
    display: flex;
    align-items: center;
    width: 1040px;
    margin: 0 auto;
    height: 64px;
    justify-content: space-between;
    line-height: 64px;
    .logo {
        display: flex;
        margin-left: 20px;
        align-items: center;
        cursor: pointer;
        .icon {
            width: 40px;
            height: 40px;
            margin: 0 20px;
        }
        .name {
            width: 94px;
            height: 20px;
        }
    }
    .navs {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-left: 50px;
        .nav {
            height: 100%;
            color: #02283f;
            text-decoration: none;
            cursor: pointer;
        }
        .active {
            color: #0060FF;
        }
    }
}
</style>